import { permissions } from '@dvag/dfs-constant-config';
import { usePageAction } from '@dvag/dfs-ui-blocks/hooks';
import { useCallback, useMemo } from 'react';

import { featureFlag } from 'config/featureFlag';
import { i18next } from 'config/i18n';

import { getToken } from '../graphql/getGraphQLClient';
import { useGetConfigBurgerMenu } from '../hooks/useGetConfigBurgerMenu';
import { usePageActionsCallbacks } from '../hooks/usePageActionsCallbacks';
import { getContext } from '../services/api/helpers';

import { useCurrentNode } from './useCurrentNode';

import type { PageDto } from '../graphql/configuration/generates';
import type { PageCodes } from '@dvag/dfs-constant-config';
import type { MenuItemType } from '@dvag/dfs-ui-blocks/hooks/usePageAction';

type SubmenuCodes =
  | 'EMPLOYMENT_INSURANCE_SUBMENU'
  | 'SECURITY_PLAN_SUBMENU'
  | 'BASIC_PENSION_SUBMENU'
  | 'FINANCIAL_GRADE_SUBMENU'
  | 'FFG_SUBMENU'
  | 'ON_GOOD_COOPERATION_SUBMENU'
  | 'CONCEPT_WITH_STRATEGY_SUBMENU'
  | 'ANALYSIS_SUBMENU'
  | 'PLANNING_SUBMENU'
  | 'ADVISORY_SUBMENU'
  | 'DREAM_JOB_SUBMENU'
  | 'CUSTOMERS_RECRUIT_CUSTOMERS_SUBMENU'
  | 'FFG_TWO_MODEL_ACCOUNT_ADVISORY'
  | 'FFG_TWO_MODEL_ACCOUNT_ANALYSIS';

type Codes = PageCodes | SubmenuCodes;

type MenuItem = {
  subMenu?: MenuItem[];
  name: string;
  alias: string[];
  id: Codes;
  isVisible: boolean;
  isHiddenInMenu?: boolean;
  action?: () => void;
};

type StartMenuItem = {
  id: Codes;
  isHiddenInMenu?: boolean;
  subMenu?: StartMenuItem[];
  action?: () => void;
  type?: MenuItemType;
};

const checkIsItemVisible = (code?: string, householdId?: string) => {
  if (code === 'HOUSEHOLD_DASHBOARD') return true;
  if (code === 'COMPANY_PRESENTATION_EXTERNAL') return !householdId;
  return !!householdId;
};

const getPageId = (pageItem: StartMenuItem): Codes =>
  pageItem.type && pageItem.id === 'FFG_TWO_MODEL_ACCOUNT'
    ? `${pageItem.id}_${pageItem.type}`
    : pageItem.id;

export const useGetNavigationItems = ({ householdId }: { householdId?: string }): MenuItem[] => {
  const configBurgerMenu = useGetConfigBurgerMenu();

  const newCatalogDashboard = featureFlag.gb?.isOn('newCatalogDashboard');

  const {
    navigateCallback,
    checkHasConsentCallback,
    checkHasAllAdultsConsentCallback,
    checkHasAllPersonsBirthdayCallback,
    errorAlertCallback,
    userPermissions,
  } = usePageActionsCallbacks(householdId);

  const { pageAction } = usePageAction({
    navigate: navigateCallback,
    currentLocation: window.location.pathname,
    hasConsent: checkHasConsentCallback,
    hasAllAdultsConsent: checkHasAllAdultsConsentCallback,
    hasAllPersonsBirthDate: checkHasAllPersonsBirthdayCallback,
    errorToast: errorAlertCallback,
    householdId,
    newCatalogDashboard,
    permission: userPermissions,
    getContext,
    getToken,
  });

  const callBackPageAction = useCallback(
    (code: PageCodes, type?: MenuItemType) => pageAction(code, type),
    [pageAction],
  );

  const getPageHasPermission = useCallback(
    ({ featureFlags, permissions: permissionMap }: PageDto) =>
      featureFlags?.every((featureFlagCode) => featureFlag.gb?.isOn(featureFlagCode)) &&
      permissionMap?.every((permission) => userPermissions.includes(permission)),
    [userPermissions],
  );

  const nSimulationFlag = featureFlag.gb?.isOn(permissions.canAccessNSimulation);

  const getPageName = useCallback(
    (code: Codes): string => {
      if (code === 'PERSONAL_FINANCIAL_PLANNING' && nSimulationFlag) {
        return i18next.t(`pages.FUTURE_HOUSEHOLD_BUDGET`, { ns: 'pages' });
      }
      return i18next.t(`pages.${code}`, { ns: 'pages' });
    },
    [nSimulationFlag],
  );

  const currentNode = useCurrentNode();

  const getMenuItemListWithData = useCallback(
    (list: StartMenuItem[]): MenuItem[] =>
      list.map((menuItem) => {
        const newMenuItem = { ...menuItem };
        const { subMenu, id } = menuItem;
        let newSubMenu: MenuItem[] | undefined;
        if (subMenu) {
          newSubMenu = getMenuItemListWithData(subMenu);
        }
        const dataMenuItem: PageDto | undefined = configBurgerMenu.data?.find(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ({ code }: any) => code === id,
        );

        const menuItemWithData: MenuItem = {
          id: getPageId(newMenuItem),
          isVisible:
            checkIsItemVisible(menuItem.id, householdId) &&
            (dataMenuItem ? getPageHasPermission(dataMenuItem) : true),
          isHiddenInMenu: newMenuItem.isHiddenInMenu,
          subMenu: newSubMenu,
          alias: dataMenuItem?.aliases || [],
          name: getPageName(getPageId(newMenuItem)),
          ...(!newMenuItem.subMenu && {
            action: () => callBackPageAction(id as PageCodes, newMenuItem.type),
          }),
        };
        return menuItemWithData;
      }),
    [configBurgerMenu.data, getPageHasPermission, getPageName, householdId, callBackPageAction],
  );

  const recommendationSubpages: StartMenuItem[] = useMemo(() => {
    const result: StartMenuItem[] = [
      {
        id: 'CUSTOMERS_RECRUIT_CUSTOMERS_PRESENTATION',
      },
    ];
    if (currentNode?.context?.userData?.advisor?.urlList?.homepage) {
      result.push({
        id: 'CUSTOMERS_RECRUIT_CUSTOMERS_DIRECT_ENTRY',
      });
    }
    return result;
  }, [currentNode?.context?.userData?.advisor?.urlList?.homepage]);

  const financialGradeSubmenu: StartMenuItem[] = useMemo(
    () => [
      {
        id: 'FINANCIAL_GRADE_START',
      },
      {
        id: 'FINANCIAL_GRADE',
      },
    ],
    [],
  );

  const formulaFinancialHappinessSubmenu: StartMenuItem[] = useMemo(
    () => [
      {
        id: 'FFG_START',
      },
      {
        id: 'FFG_SAVINGS_TYPE',
      },
      {
        id: 'FFG_EXPLANATION',
      },
      {
        id: 'FFG_INCOME',
      },
      {
        id: 'FFG_OPTIMUM_CALCULATION',
      },
      {
        id: 'FFG_HOUSEHOLD_BUDGET',
      },
      {
        id: 'FFG_CONTRACT_OVERVIEW',
      },
      {
        id: 'TARGET_ACTUAL_COMPARISON',
      },
      {
        id: 'FFG_SAVINGS_PLAN',
      },
      {
        id: 'FFG_TWO_MODEL_ACCOUNT',
        type: 'ANALYSIS',
      },
    ],
    [],
  );

  const onGoodCooperationSubmenu: StartMenuItem[] = useMemo(
    () => [
      {
        id: 'ON_GOOD_COOPERATION_FEEDBACK',
      },
      {
        id: 'ON_GOOD_COOPERATION_WISHES_REFERRALS',
      },
      {
        id: 'ON_GOOD_COOPERATION_RECRUITING',
      },
    ],
    [],
  );

  const workerProtectionSubMenu: StartMenuItem[] = useMemo(
    () => [
      {
        id: 'EMPLOYMENT_INSURANCE',
      },
      {
        id: 'EMPLOYMENT_INSURANCE_SIMULATION',
      },
    ],
    [],
  );

  const wealthBuildingAndSecurityPlanSubMenu: StartMenuItem[] = useMemo(
    () => [
      {
        id: 'SECURITY_PLAN',
      },
      {
        id: 'SECURITY_PLAN_SIMULATION',
      },
    ],
    [],
  );

  const basicPensionSubMenu: StartMenuItem[] = useMemo(
    () => [
      {
        id: 'BASIC_PENSION',
      },
      {
        id: 'BASIC_PENSION_SIMULATION',
      },
    ],
    [],
  );

  const conceptWithStrategySubMenu: StartMenuItem[] = useMemo(
    () => [
      {
        id: 'FFG_TWO_MODEL_ACCOUNT',
        type: 'ADVISORY',
      },
      nSimulationFlag
        ? {
            id: 'EMPLOYMENT_INSURANCE_SUBMENU',
            subMenu: workerProtectionSubMenu,
          }
        : {
            id: 'EMPLOYMENT_INSURANCE',
          },
      nSimulationFlag
        ? {
            id: 'SECURITY_PLAN_SUBMENU',
            subMenu: wealthBuildingAndSecurityPlanSubMenu,
          }
        : {
            id: 'SECURITY_PLAN',
          },
      nSimulationFlag
        ? {
            id: 'BASIC_PENSION_SUBMENU',
            subMenu: basicPensionSubMenu,
          }
        : {
            id: 'BASIC_PENSION',
          },
      {
        id: 'BUILDING_SOCIETY_SAVINGS',
      },
      {
        id: 'PENSION_PLANNER',
      },
      {
        id: 'INVESTMENT',
      },
    ],
    [
      basicPensionSubMenu,
      nSimulationFlag,
      wealthBuildingAndSecurityPlanSubMenu,
      workerProtectionSubMenu,
    ],
  );

  const analysisSubmenu: StartMenuItem[] = useMemo(
    () => [
      {
        id: 'ANALYSIS_DASHBOARD',
      },
      {
        id: 'COMPANY_PRESENTATION',
      },
      {
        id: 'WISHES_GOALS',
      },
      {
        id: 'FINANCIAL_GRADE_SUBMENU',
        subMenu: financialGradeSubmenu,
      },
      {
        id: 'FFG_SUBMENU',
        subMenu: formulaFinancialHappinessSubmenu,
      },
      {
        id: 'ON_GOOD_COOPERATION_SUBMENU',
        subMenu: onGoodCooperationSubmenu,
      },
      {
        id: 'PERSONAL_DATA',
      },
    ],
    [financialGradeSubmenu, formulaFinancialHappinessSubmenu, onGoodCooperationSubmenu],
  );

  const planningSubmenu: StartMenuItem[] = useMemo(
    () => [
      {
        id: 'DATATURBO_DOCUMENTS',
      },
      {
        id: 'ANALYSIS_EVALUATION',
      },
      {
        id: 'WEALTH_PLANNING',
      },
      {
        id: 'COACH_REPORT',
      },
    ],
    [],
  );
  const advisorySubmenu: StartMenuItem[] = useMemo(
    () => [
      {
        id: 'ADVISORY_DASHBOARD',
      },
      {
        id: 'RECAP',
      },
      {
        id: 'FINANCIAL_BALANCE_SHEET',
      },
      {
        id: 'CONCEPT_WITH_STRATEGY_SUBMENU',
        subMenu: conceptWithStrategySubMenu,
      },
      {
        id: 'PERSONAL_FINANCIAL_PLANNING',
      },
    ],
    [conceptWithStrategySubMenu],
  );

  const dreamJobSubMenu: StartMenuItem[] = useMemo(
    () => [
      {
        id: 'DREAM_JOB_PRESENTATION',
      },
      {
        id: 'DREAM_JOB_DIRECT_ENTRY',
      },
    ],
    [],
  );

  const menuItemList: StartMenuItem[] = useMemo(
    () => [
      {
        id: 'HOUSEHOLD_DASHBOARD',
      },
      {
        id: 'COMPANY_PRESENTATION_EXTERNAL',
      },
      {
        id: 'ANALYSIS_SUBMENU',
        subMenu: analysisSubmenu,
      },
      {
        id: 'PLANNING_SUBMENU',
        subMenu: planningSubmenu,
      },
      {
        id: 'ADVISORY_SUBMENU',
        subMenu: advisorySubmenu,
      },
      {
        id: 'DREAM_JOB_SUBMENU',
        isHiddenInMenu: true,
        subMenu: dreamJobSubMenu,
      },
      {
        id: 'CUSTOMERS_RECRUIT_CUSTOMERS_SUBMENU',
        isHiddenInMenu: true,
        subMenu: recommendationSubpages,
      },
      {
        id: 'PENSION',
        isHiddenInMenu: true,
      },
      {
        id: 'LIVING_SITUATION',
        isHiddenInMenu: true,
      },
      {
        id: 'APPOINTMENT_DOCUMENTATION',
        isHiddenInMenu: true,
      },
      {
        id: 'FIVE_STEPS',
        isHiddenInMenu: true,
      },
    ],
    [advisorySubmenu, analysisSubmenu, dreamJobSubMenu, planningSubmenu, recommendationSubpages],
  );

  return useMemo(
    () => (!configBurgerMenu.data ? [] : getMenuItemListWithData(menuItemList)),
    [getMenuItemListWithData, configBurgerMenu.data, menuItemList],
  );
};
